.modal-dialog {
    max-width: 90% !important;
}


.css-dxk7li-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon, .css-dxk7li-MuiButtonBase-root-MuiChip-root {
    color: #ffffff !important;
}

.modal-50w {
    max-width: 50% !important
} 

.modal-75w {
    max-width: 75% !important;
}

.css-4z49zx-MuiSlider-root {
    display: none !important;
}

p {
    font-size: 15px !important;
}

